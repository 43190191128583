import { get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const getAllPermissions = () => get(`permission-group`);

export const create = (clientId, role) => post(`client/${clientId}/role`, role);

export const update = (clientId, role) => put(`client/${clientId}/role/${role.id}`, role);

export const index = (clientId, ...args) => getPaginated(`client/${clientId}/role`, ...args);

export const autocomplete = async (roleId, ...args) => {
  
  let result = await getPaginated(`role/autocomplete`, ...args)

  result.data.data = result.data.data.filter(role => role.id != roleId)

  return result

};

export const show = (clientId, roleId) => get(`client/${clientId}/role/${roleId}`);
