<template>
  <KPaginatedAutocomplete v-bind="$attrs" v-on="$listeners" :paginator="autocomplete"/>
</template>

<script>import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.vue';
import {autocomplete} from '@/modules/roles/api/role.js';

export default {
  name: 'RoleAutocomplete',
  props: {
    roleId: {
      type: Number
    }
  },
  components: {KPaginatedAutocomplete},
  methods: {
    autocomplete() {
      return autocomplete(this.roleId);
    }
  },
};
</script>
