<template>
  <v-treeview
      v-model="selectedStores"
      :items="items"
      selected-color="primary"
      item-children="stores"
      open-all
      selectable
      open-on-click
      transition
      dense
  ></v-treeview>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
    },
    items: {
      type: Array,
    },
  },
  computed: {
    selectedStores: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
